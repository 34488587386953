import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChartLine,
  faGraduationCap,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { dateStringToFormattedDate } from '@livepolls/ui-components/src/utils/DateTimeUtil';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  goToLivePollEditURL,
  goToTakeLivePoll,
  goToTakeLivePollSessionsURL,
} from 'src/components/navbar/app.urls';
import analyticsImg from 'src/images/analytics.svg';
import deleteImg from 'src/images/delete.svg';
import penImg from 'src/images/pen.svg';
import { LivePollListSortBy } from 'src/models/livepoll-list-sort-by.enum';
import { IFetchLivePollsSortingParams } from 'src/models/livepoll-list-sorting-params.interface';
import { ILivePoll } from 'src/models/livepoll.interface';
import styles from './LivePollList.module.css';
import { LivePollStarUnStar } from './LivePollStarUnStar';
import { StartLivePollModal } from 'src/components/start-livepoll/start-livepoll-modal';
import { useAddLivePollSessionMutation } from 'src/hooks/store-hooks';
import { ILivePollSessionSetting } from 'src/models/livepoll-session-setting.interface';
import { ILivePollWithStats } from 'src/models/livepoll-total-respondents-and-sessions.interface';
import { SearchLivePollsByName } from './SearchLivePollsByName';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { LpPaginateDropdown } from '@livepolls/ui-components/src/components/pagination-dropdown/LpPaginationDropdown';
import { useTranslation } from 'react-i18next';
import { TableHead } from 'src/components/table-head/TableHead';
import { SortOrder } from 'src/models/sort-order.enum';
import threeDotsSvg from 'src/images/three-dots.svg';
import { useOnClickOutside } from '@livepolls/ui-components/src/utils/hooks.util';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

const TRIPLE_DOT_MENU_HEIGHT = 25;
const TRIPLE_DOT_MENU_WIDTH = 50;

interface Props {
  sortingParams: IFetchLivePollsSortingParams;
  pager: IPaginateDropdown;
  totalItems: number;
  livePolls?: ILivePollWithStats[];
  onTableHeadClick: (sortingParams: IFetchLivePollsSortingParams) => void;
  onDeleteIconClick: (e: React.MouseEvent, livePollId: number) => void;
  onPageChange: (pager: IPaginateDropdown) => void;
  searchText: string | null;
}

export const LivePollListTable = ({
  sortingParams,
  livePolls,
  pager,
  totalItems,
  onTableHeadClick,
  onDeleteIconClick,
  onPageChange,
  searchText,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useRef(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPopupForName, setShowPopupForName] = useState<boolean>(false);
  const addLivePollSessionMutation = useAddLivePollSessionMutation();
  const [activeLivePollId, setActiveLivePollId] = useState<number>(0);
  const [activeLivePollType, setActiveLivePollType] = useState<LivePollType>(
    LivePollType.QUIZ,
  );
  const refs = useRef<HTMLDivElement[] | null[]>([]);
  const [livePollDetails, setClickedLivePollDetails] = useState<{
    type: LivePollType;
    id: number;
  }>({ type: LivePollType.QUIZ, id: 0 });
  const livePollSettingRef = useRef<HTMLDivElement>(null);
  const livePollListRef = useRef<HTMLDivElement>(null);
  const tableRowRef = useRef<HTMLTableRowElement | null>(null);
  const activeTripleDotRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const [isSettingModalOpen, setIsSettingModalOpen] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const livePollSession = addLivePollSessionMutation.data;
    if (livePollSession) {
      goToTakeLivePoll(navigate, activeLivePollId, livePollSession.id);
    }
  }, [activeLivePollId, addLivePollSessionMutation.data, navigate]);

  useOnClickOutside(livePollSettingRef, activeTripleDotRef, () => {
    if (isSettingModalOpen) {
      closeSetting();
    }
  });

  const handleSortColumn = (sortByColumn: LivePollListSortBy) => {
    if (sortByColumn === LivePollListSortBy.NAME) {
      setShowPopupForName(true);
    } else {
      const sortOrder =
        sortingParams.sortOrder === SortOrder.ASC
          ? SortOrder.DESC
          : SortOrder.ASC;
      onTableHeadClick({ sortBy: sortByColumn, sortOrder, title: searchText });
    }
  };

  const handleEditIconClick = (livePollId: number) => {
    goToLivePollEditURL(navigate, livePollId);
  };

  const handleSessionIconClick = (livePollId: number) => {
    goToTakeLivePollSessionsURL(navigate, livePollId);
  };

  const handleDeleteIconClick = (e: React.MouseEvent, livePollId: number) => {
    onDeleteIconClick(e, livePollId);
  };

  const sortIcon = (sortBy: LivePollListSortBy): IconProp => {
    if (sortBy === sortingParams.sortBy) {
      return sortingParams.sortOrder === SortOrder.ASC ? faSortUp : faSortDown;
    } else {
      return faSort;
    }
  };

  const handleShowModal = (livePoll: ILivePoll) => {
    setActiveLivePollId(livePoll.id);
    setActiveLivePollType(livePoll.type);
    setShowModal(true);
  };

  const handleAddLivePollSession = async (data: ILivePollSessionSetting) => {
    addLivePollSessionMutation.mutate({
      livePollId: activeLivePollId,
      data: { ...data },
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const closePopup = () => {
    setShowPopupForName(false);
  };

  const onPopupClick = (params: IFetchLivePollsSortingParams) => {
    const { sortBy, sortOrder, title } = params;
    onTableHeadClick({ sortBy, sortOrder, title });
    closePopup();
  };

  const openSetting = (livePoll: ILivePoll, index: number) => {
    setIsSettingModalOpen(prevState => {
      if (!prevState) {
        setClickedLivePollDetails({ type: livePoll.type, id: livePoll.id });
        const tripleDotDiv = refs.current[index];
        const livePollListDiv = livePollListRef.current;
        if (tripleDotDiv && livePollListDiv) {
          activeTripleDotRef.current = tripleDotDiv;

          const rect = tripleDotDiv.getBoundingClientRect();
          const containerRect = livePollListDiv.getBoundingClientRect();
          const top = rect.top - containerRect.top + TRIPLE_DOT_MENU_HEIGHT;
          const left = rect.left - containerRect.left - TRIPLE_DOT_MENU_WIDTH;

          if (livePollSettingRef.current) {
            livePollSettingRef.current.style.display = 'flex';
            livePollSettingRef.current.style.top = `${top}px`;
            livePollSettingRef.current.style.left = `${left}px`;

            // find parent tr element of clicked triple dot
            const activeTableRow = tripleDotDiv.closest('tr');
            if (activeTableRow) {
              activeTableRow.classList.add(styles.activeRow);
              tableRowRef.current = activeTableRow;
            }
          }
        }
      } else {
        closeSetting();
      }

      return !prevState;
    });
  };

  const closeSetting = () => {
    setIsSettingModalOpen(false);

    if (livePollSettingRef.current && tableRowRef.current) {
      livePollSettingRef.current.style.display = 'none';
      activeTripleDotRef.current = null;
    }

    if (tableRowRef.current) {
      tableRowRef.current.classList.remove(styles.activeRow);
      tableRowRef.current = null;
    }
  };

  const isNameColumnActive = !!(searchText && searchText?.length > 0);
  return (
    <div className={styles.container} ref={livePollListRef}>
      <div className={styles.paginationContainer}>
        <LpPaginateDropdown
          pager={pager}
          onPageChange={onPageChange}
          totalItems={totalItems}
          enablePerPage={true}
          customDropdownContentClass={styles.dropDownContentClass}
          text={{
            showing: t('showing'),
            showUpto: t('showUpTo'),
            of: t('of'),
            to: t('to'),
          }}
        />
      </div>
      <div className={`${styles.tableContainer} ${styles.includePagination}`}>
        <table className={styles.livePollListTable}>
          <thead>
            <tr>
              <TableHead
                text={t('starred')}
                className={styles.starredColumnHead}
                onClick={() => handleSortColumn(LivePollListSortBy.STARRED)}
                icon={sortIcon(LivePollListSortBy.STARRED)}
              />
              <TableHead
                text={t('name')}
                className={styles.nameColumnHead}
                onClick={() => handleSortColumn(LivePollListSortBy.NAME)}
                icon={sortIcon(LivePollListSortBy.NAME)}
                active={isNameColumnActive}
              />
              <TableHead
                text={t('type')}
                className={styles.typeColumnHead}
                onClick={() => handleSortColumn(LivePollListSortBy.TYPE)}
                icon={sortIcon(LivePollListSortBy.TYPE)}
              />

              <TableHead
                text={t('lastActive')}
                onClick={() =>
                  handleSortColumn(LivePollListSortBy.LAST_SESSION)
                }
                icon={sortIcon(LivePollListSortBy.LAST_SESSION)}
              />

              <TableHead
                text={t('totalSessions')}
                onClick={() =>
                  handleSortColumn(LivePollListSortBy.TOTAL_SESSIONS)
                }
                icon={sortIcon(LivePollListSortBy.TOTAL_SESSIONS)}
              />

              <TableHead
                text={t('participantCount')}
                onClick={() =>
                  handleSortColumn(LivePollListSortBy.TOTAL_PARTICIPANTS)
                }
                className={styles.participantCountColumHead}
                icon={sortIcon(LivePollListSortBy.TOTAL_PARTICIPANTS)}
              />

              <th className={styles.hoverOptionsColumnHead} />
            </tr>
          </thead>

          <tbody>
            {livePolls?.map((livePoll, index) => (
              <tr key={livePoll.id}>
                <td className={styles.iconTableData}>
                  <LivePollStarUnStar
                    livePoll={livePoll}
                    sortingParams={sortingParams}
                    paginationParams={pager}
                  />
                </td>
                <td
                  data-testid="livePolls-table-title-data"
                  className={styles.titleCell}
                >
                  <Link to={`${livePoll.id}`} className={styles.titleLink}>
                    {' '}
                    {livePoll.title ? livePoll.title : t('untitled')}{' '}
                  </Link>
                </td>
                <td
                  className={
                    styles.iconTableData + ' ' + styles.livePollTypeIcon
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      livePoll.type === LivePollType.POLL
                        ? faChartLine
                        : faGraduationCap
                    }
                    size="lg"
                    data-testid="livePolls-table-type-icon"
                  />
                </td>

                <td data-testid="livePolls-table-lastSessionStartedAt-data">
                  {livePoll.lastSessionStartedAt
                    ? dateStringToFormattedDate(
                        livePoll.lastSessionStartedAt.toString(),
                      )
                    : '-'}
                </td>
                <td>
                  <div className={styles.sessionCountData}>
                    {livePoll.totalSessions}
                  </div>
                </td>
                <td>
                  <div className={styles.participantCountData}>
                    {livePoll.totalParticipants}
                  </div>
                </td>
                <td className={styles.iconTableData}>
                  <div className={styles.hoverOptions}>
                    <WuButton
                      className={styles.startSessionText}
                      variant={'secondary'}
                      onClick={() => handleShowModal(livePoll)}
                    >
                      {t('startSession')}
                    </WuButton>

                    <div className={styles.hoverOptions}>
                      <div className={styles.hoverOptionsIcons}>
                        <img
                          src={penImg}
                          alt="pen icon"
                          onClick={() => handleEditIconClick(livePoll.id)}
                          data-testid="livePolls-table-goto-edit-icon"
                        />
                      </div>
                      <div className={styles.analyticsIconWrapper}>
                        <img
                          alt="analytics icon"
                          src={analyticsImg}
                          data-testid="livePolls-table-goto-session-analytics-icon"
                          className={
                            styles.hoverOptionsIcons +
                            ' ' +
                            styles.analyticsIcon
                          }
                          onClick={() => handleSessionIconClick(livePoll.id)}
                        />
                      </div>
                      <div className={styles.hoverOptionsIcons}>
                        <img
                          src={deleteImg}
                          alt="delete icon"
                          onClick={e => handleDeleteIconClick(e, livePoll.id)}
                          data-testid="livePolls-table-delete-livepoll-icon"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={styles.threeDots}
                    ref={el => (refs.current[index] = el)}
                  >
                    <span
                      aria-label={'Open livePoll setting'}
                      className={
                        true ? styles.activeToggleButton : styles.toggleButton
                      }
                      onClick={() => openSetting(livePoll, index)}
                    >
                      <img src={threeDotsSvg} alt="livePoll setting" />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <StartLivePollModal
          onDeploy={handleAddLivePollSession}
          onCloseModal={handleCloseModal}
          livePollType={activeLivePollType}
          isLoading={addLivePollSessionMutation.isLoading}
          error={addLivePollSessionMutation.error}
        />
      )}

      {showPopupForName && (
        <SearchLivePollsByName
          onTableHeadClick={onTableHeadClick}
          onPopupClick={onPopupClick}
          searchText={searchText}
          sortingParams={sortingParams}
          closePopup={() => closePopup()}
        />
      )}

      <div className={styles.optionsContainer} ref={livePollSettingRef}>
        <div
          onClick={() => handleShowModal(livePollDetails as ILivePoll)}
          className={styles.option}
        >
          {t('startSession')}
        </div>
        <div className={styles.line}></div>
        <div
          onClick={() => handleEditIconClick(livePollDetails.id)}
          className={styles.option}
        >
          <img src={penImg} alt="pen icon" />
          <span>Edit</span>
        </div>
        <div
          onClick={() => handleSessionIconClick(livePollDetails.id)}
          className={styles.option}
        >
          <img src={analyticsImg} alt="analytics icon" />
          <span>Analytics</span>
        </div>
        <div
          onClick={e => handleDeleteIconClick(e, livePollDetails.id)}
          className={styles.option}
        >
          <img src={deleteImg} alt="delete icon" />
          <span>Delete</span>
        </div>
      </div>
    </div>
  );
};
