import React, { useState } from 'react';
import { DEFAULT_TEAM_NAMES } from 'src/constants/default-team-names.constants';
import { ILivePollSessionSetting } from 'src/models/livepoll-session-setting.interface';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { ITeamSetting } from '@livepolls/ui-components/src/interfaces/team-setting.interface';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';
import { InputLabel } from 'src/wick-ui/input/InputLabel';
import { TextInput } from 'src/wick-ui/input/TextInput';
import Modal from 'src/wick-ui/modal/Modal';
import { LpDropdown } from '@livepolls/ui-components/src/components/dropdown/LpDropdown';
import styles from './start-livepoll-modal.module.css';
import { TeamSetting } from './team-setting';
import { useTranslation } from 'react-i18next';
import infoSvg from '../../images/info.svg';
import { WuToggle } from '@npm-questionpro/wick-ui-lib';

interface Props {
  onDeploy(data: ILivePollSessionSetting): void;
  onCloseModal(): void;
  isLoading: boolean;
  error: Error | null;
  livePollType: LivePollType;
}

export const StartLivePollModal = ({
  onDeploy,
  onCloseModal,
  livePollType,
  isLoading,
  error,
}: Props) => {
  const { t } = useTranslation();

  const RESPONSE_TIME_LIMITS_WITH_TEXT = [
    { text: t('none'), timeLimit: 0 },
    { text: `10 ${t('seconds')}`, timeLimit: 10 },
    { text: `20 ${t('seconds')}`, timeLimit: 20 },
    { text: `30 ${t('seconds')}`, timeLimit: 30 },
    { text: `1 ${t('minute')}`, timeLimit: 60 },
    { text: `1 ${t('minute')} 30 ${t('seconds')}`, timeLimit: 90 },
    { text: `2 ${t('minutes')}`, timeLimit: 120 },
    { text: `5 ${t('minutes')}`, timeLimit: 300 },
  ];

  const [sessionName, setSessionName] = useState<string>('');
  const [
    selectedResponseTimeLimitWithText,
    setSelectedResponseTimeLimitWithText,
  ] = useState<{
    text: string;
    timeLimit: number;
  }>(RESPONSE_TIME_LIMITS_WITH_TEXT[0]);

  const [enableAnonymousResponses, setEnableAnonymousResponses] =
    useState<boolean>(false);
  const [enableDisplayDifficultyLevels, setEnableDisplayDifficultyLevels] =
    useState<boolean>(false);
  const [enableTeams, setEnableTeams] = useState<boolean>(false);
  const [enablePresenterNotes, setEnablePresenterNotes] =
    useState<boolean>(false);
  const [teamSize, setTeamSize] = useState<number>(0);
  const [manualAssignTeams, setManualAssignTeams] = useState<boolean>(false);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleSelectResponseTimeLimit = (selectedTimeLimit: number) => {
    const selectedResponseTimeLimitWithText =
      RESPONSE_TIME_LIMITS_WITH_TEXT.find(
        responseTimeLimit => responseTimeLimit.timeLimit === selectedTimeLimit,
      )!;
    setSelectedResponseTimeLimitWithText(selectedResponseTimeLimitWithText);
  };

  const areTeamsValid = (): boolean => {
    setValidationErrors([]);
    const errors = [];

    teams.map((team, index) => {
      if (team.name.trim() === '') {
        team.name = DEFAULT_TEAM_NAMES[index];
      }
      return team;
    });

    // check if team names and colors are unique
    const nameSet = new Set<string>();
    const colorSet = new Set<string>();
    teams.forEach(team => {
      nameSet.add(team.name);
      colorSet.add(team.color);
    });

    if (nameSet.size !== teamSize) {
      errors.push(t('teamNameUniqueError'));
    }

    if (colorSet.size !== teamSize) {
      errors.push(t('teamColorUniqueError'));
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const responseTimeLimit = selectedResponseTimeLimitWithText.timeLimit;
    const teamSetting: ITeamSetting = {
      enabled: enableTeams,
    };

    if (enableTeams) {
      if (!areTeamsValid()) {
        return;
      }
      teamSetting.teamCount = teamSize;
      teamSetting.manualAssign = manualAssignTeams;
      teamSetting.teams = [...teams];
    }

    onDeploy({
      name: sessionName,
      responseTimeLimitInSecs: responseTimeLimit,
      anonymousResponses: enableAnonymousResponses,
      displayDifficultyLevels: enableDisplayDifficultyLevels,
      teamSetting,
      presenterNotes: enablePresenterNotes,
    });
  };

  const submitText = t('start');
  const responseTimeLimitListItems = RESPONSE_TIME_LIMITS_WITH_TEXT.map(
    responseTimeLimit => (
      <li
        key={responseTimeLimit.timeLimit}
        onClick={() =>
          handleSelectResponseTimeLimit(responseTimeLimit.timeLimit)
        }
        className={styles.listItem}
      >
        {responseTimeLimit.text}
      </li>
    ),
  );

  let content = (
    <div className={styles.modal}>
      <div className={styles.sessionNameLabel}>{t('sessionName')}</div>
      <TextInput
        autoFocus={true}
        placeholder={t('sessionNamePlaceholder')}
        initialValue={sessionName}
        onChange={setSessionName}
        maxLength={60}
      />
      <div className={styles.sessionNameLength}>{sessionName.length}/60</div>

      <InputLabel text={t('limitResponseTime')} fullWidth={false}>
        <LpDropdown text={selectedResponseTimeLimitWithText.text}>
          <ul>{responseTimeLimitListItems}</ul>
        </LpDropdown>
      </InputLabel>

      {livePollType === LivePollType.QUIZ && (
        <InputLabel text={t('displayDifficultyLevels')} fullWidth={false}>
          <div className={styles.toggle}>
            <WuToggle onChange={setEnableDisplayDifficultyLevels} />
          </div>
        </InputLabel>
      )}

      <InputLabel
        text={t('anonymity')}
        description={t('anonymityDesc')}
        fullWidth={false}
      >
        <div className={styles.toggle}>
          <WuToggle onChange={setEnableAnonymousResponses} />
        </div>
      </InputLabel>

      {livePollType === LivePollType.QUIZ && (
        <InputLabel text={t('startInTeams')} fullWidth={false}>
          <div className={styles.toggle}>
            <WuToggle onChange={setEnableTeams} />
          </div>
        </InputLabel>
      )}

      {enableTeams && (
        <TeamSetting
          teamSize={teamSize}
          teams={teams}
          manualAssignTeams={manualAssignTeams}
          anonymousResponses={enableAnonymousResponses}
          onChangeTeamSize={setTeamSize}
          onChangeManualAssign={setManualAssignTeams}
          onChangeTeams={setTeams}
        />
      )}

      <InputLabel text={t('speakerNotes')} fullWidth={false}>
        <div className={styles.toggle}>
          <WuToggle onChange={setEnablePresenterNotes} />
        </div>
      </InputLabel>
      <div className={styles.speakerNotesDescForQuiz}>
        {enablePresenterNotes && (
          <>
            <div>
              <img src={infoSvg} alt="info text" />
            </div>
            <div> {t('speakerNotesDesc')}</div>
          </>
        )}
      </div>

      {validationErrors.map((validationError, index) => (
        <p key={index} className={styles.validationError}>
          * {validationError}
        </p>
      ))}
    </div>
  );

  if (error) {
    content = (
      <div>Oops an error occurred starting new session. ({error?.message})</div>
    );
  }

  return (
    <Modal
      title={t('startNewSession')}
      closePopup={onCloseModal}
      onSubmit={handleSubmit}
      submitText={submitText}
      loading={isLoading}
      popupWidth={1200}
    >
      {content}
    </Modal>
  );
};
