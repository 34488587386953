import { useState } from 'react';
import {
  useAddLivePollFromTemplate,
  useTemplateQuestions,
} from 'src/hooks/store-hooks';
import { ITemplateQuestionAnswer } from 'src/models/template-question-answer.interface';
import { ITemplateQuestion } from 'src/models/template-question.interface';
import { ITemplate } from 'src/models/template.interface';
import { redirectTo } from 'src/utils/redirect.util';
import Modal from 'src/wick-ui/modal/Modal';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import styles from './add-livepoll-from-template-modal.module.css';
import { useTranslation } from 'react-i18next';
import { WuRadioGroup } from '@npm-questionpro/wick-ui-lib';
interface Props {
  template: ITemplate;
  closePopup(): void;
}

export const AddLivePollFromTemplateModal = ({
  template,
  closePopup,
}: Props) => {
  const { t } = useTranslation();

  const {
    isError,
    isLoading,
    data: templateQuestions,
  } = useTemplateQuestions(template.id);
  const questions = templateQuestions?.questions;
  const addLivePollFromTemplateMutation = useAddLivePollFromTemplate();
  const [creatingLivePoll, setCreatingLivePoll] = useState<boolean>(false);
  const title = `${t('preview')}:  ${template.title}`;
  const [createLivePollError, setCreateLivePollError] =
    useState<boolean>(false);

  const footerLeftText =
    template.questionCount === 1
      ? `1 Question`
      : `${template.questionCount} ${t('questions')}`;

  const handleUseTemplate = async () => {
    try {
      setCreatingLivePoll(true);
      const livePoll = await addLivePollFromTemplateMutation.mutateAsync(
        template.id,
      );

      if (!livePoll) {
        throw new Error(t('createLivePollFromTemplateError'));
      }

      redirectTo(`/livepolls/${livePoll.id}`);
    } catch (err) {
      setCreatingLivePoll(false);
      setCreateLivePollError(true);
    }
  };

  if (isError) {
    throw new Error(t('fetchQuestionsError'));
  }

  if (createLivePollError) {
    throw new Error(t('addLivePollError'));
  }

  return (
    <Modal
      title={title}
      closePopup={closePopup}
      submitText={t('useThisTemplate')}
      onSubmit={handleUseTemplate}
      footerLeftSideText={footerLeftText}
      popupContentClass={styles.popupContent}
      popupWidth={1005}
      disableSubmitBtn={creatingLivePoll}
    >
      <div className={styles.container}>
        {isLoading && <LpSpinner message={t('loadingTemplateQuestions')} />}
        {!isLoading && !questions && <div>{t('questionsNotAvailable')}</div>}
        {!isLoading && questions && (
          <div className={styles.innerContainer}>
            {questions.map((question: ITemplateQuestion) => {
              return (
                <div key={question.id} className={styles.question}>
                  <div className={styles.questionTitle}>{question.title}</div>
                  {question.answers.map((answer: ITemplateQuestionAnswer) => {
                    return (
                      <div className={styles.answer} key={answer.id}>
                        <div className={styles.radioButton}>
                          <WuRadioGroup
                            defaultValue={'value'}
                            options={[
                              {
                                label: '',
                                value: answer.isCorrect ? 'value' : '',
                                disabled: answer.isCorrect ? false : true,
                              },
                            ]}
                          />
                        </div>
                        <div className={styles.answerText}>{answer.text}</div>
                      </div>
                    );
                  })}
                  <div className={styles.borderBottom}></div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};
