import React from 'react';
import handsSvg from 'src/images/Hands_s.svg';

export const DEFAULT_COVER_IMAGE_URL = '/src/images/Hands_s.svg';
export const isDefaultImage = (imageUrl: string): boolean => {
  return imageUrl === DEFAULT_COVER_IMAGE_URL;
};

export const coverImageBackgroundStyles = (
  imageUrl: string,
): React.CSSProperties => {
  const backgroundStyles: React.CSSProperties = {};
  if (isDefaultImage(imageUrl)) {
    backgroundStyles.backgroundImage = `url(${handsSvg})`;
    backgroundStyles.backgroundRepeat = 'repeat-x';
    backgroundStyles.backgroundPosition = 'bottom';
  } else {
    backgroundStyles.backgroundImage = `url(${imageUrl})`;
    backgroundStyles.backgroundSize = 'cover';
    backgroundStyles.backgroundPosition = 'center';
    backgroundStyles.backgroundRepeat = 'no-repeat';
  }

  return backgroundStyles;
};

export const getWaitingRoomLeftContainerStyles = (
  imageUrl: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    containerStyle.backgroundColor = '#fff';
    containerStyle.opacity = '.7';
    containerStyle.padding = '75px 20px 10px';
  }

  return containerStyle;
};

export const getQrCodeContainerStyles = (
  imageUrl: string,
  overlayType: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    if (overlayType === 'dark') {
      containerStyle.backgroundColor = 'rgba(32, 32, 32, 0.75)';
    } else if (overlayType === 'light') {
      containerStyle.backgroundColor = 'rgba(238, 238, 238, 0.75)';
    }
    containerStyle.bottom = '32px';
    containerStyle.margin = 'auto';
    containerStyle.width = 'max-content';
    containerStyle.backdropFilter = 'blur(8px)';
  } else {
    containerStyle.margin = '0 auto';
    containerStyle.width = 'max-content';
  }

  return containerStyle;
};

export const getTextColorStyles = (
  imageUrl: string,
  overlayType: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    if (overlayType === 'dark') {
      containerStyle.color = '#fff';
    } else if (overlayType === 'light') {
      containerStyle.color = '#545E6B';
    }
  }

  return containerStyle;
};

export const getSessionNameColorStyles = (
  imageUrl: string,
  overlayType: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    if (overlayType === 'light') {
      containerStyle.color = '#545E6B';
    } else if (overlayType === 'dark') {
      containerStyle.color = '#fff';
    }
    containerStyle.position = 'initial';
    containerStyle.marginTop = '32px';
  }
  return containerStyle;
};

export const getLinkTextStyles = (
  imageUrl: string,
  overlayType: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    if (overlayType === 'dark') {
      containerStyle.color = '#8DC3F2';
    } else {
      containerStyle.color = '#1b87e6';
    }
  }

  return containerStyle;
};

export const getLogoStyles = (imageUrl: string): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    containerStyle.marginTop = '32px';
  }

  return containerStyle;
};

export const getOverlayImage = (
  imageUrl: string,
  overlayType: string,
): React.CSSProperties => {
  const containerStyle: React.CSSProperties = {};
  if (imageUrl && !isDefaultImage(imageUrl)) {
    if (overlayType === 'dark') {
      containerStyle.background =
        'linear-gradient(180deg, rgba(32, 32, 32, 0.75) 20%, rgba(32, 32, 32, 0) 50%)';
      containerStyle.width = '100%';
    } else {
      containerStyle.background =
        'linear-gradient(180deg, rgba(238, 238, 238, 0.75) 20%, rgba(238, 238, 238, 0) 50%)';
      containerStyle.width = '100%';
    }
  }

  return containerStyle;
};
