import { useState } from 'react';
import { useMostPopularTemplates } from 'src/hooks/store-hooks';
import { ITemplate } from 'src/models/template.interface';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { AllTemplatesModal } from '../add-poll-from-template/all-templates/all-templates';
import { TemplateCard } from '../template-card/TemplateCard';
import styles from './TemplateRow.module.css';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import angelArrowDownSvg from 'src/images/angel-arrow-down.svg';
import angelArrowUpSvg from 'src/images/angel-arrow-up.svg';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

interface Props {
  hideShowAndHideTemplatesIcon?: boolean;
  customClass?: string;
  numTemplatesToShow?: number;
  backgroundColor?: string;
}

const DEFAULT_NUM_TEMPLATES_TO_SHOW = 5;

export const TemplateRow = ({
  hideShowAndHideTemplatesIcon,
  customClass,
  numTemplatesToShow,
  backgroundColor = '#fff',
}: Props) => {
  const { t } = useTranslation();
  const modalRoot = document.getElementById('modal-root') as HTMLElement;
  const numTemplates = numTemplatesToShow || DEFAULT_NUM_TEMPLATES_TO_SHOW;

  const {
    isError,
    isLoading,
    error,
    data: templateArray,
  } = useMostPopularTemplates();

  const [displayTemplates, setDisplayTemplates] = useState<boolean>(true);
  const [showAllTemplateModal, setShowAllTemplateModal] =
    useState<boolean>(false);

  const getIcon = () => {
    if (!displayTemplates) {
      return (
        <img
          src={angelArrowUpSvg}
          alt="show templates icon"
          className={styles.icon}
        />
      );
    }

    return (
      <img
        src={angelArrowDownSvg}
        alt="hide templates icon"
        className={styles.icon}
      />
    );
  };

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <LpSpinner message={t('loadingTemplates')} />;
  }

  if (!templateArray) {
    return <div>Templates are not available.</div>;
  }

  const templates = templateArray
    .map((template: ITemplate) => {
      return (
        <TemplateCard
          template={template}
          key={template.id}
          customClass={customClass}
        />
      );
    })
    .slice(0, numTemplates);

  const handleSeeAllTemplate = () => {
    setShowAllTemplateModal(true);
  };
  const closePopup = () => {
    setShowAllTemplateModal(false);
  };

  return (
    <div>
      <div
        className={styles.innerContainer}
        style={{ background: backgroundColor }}
      >
        <div className={styles.topRow}>
          <div
            className={styles.templatesText}
            onClick={() => setDisplayTemplates(!displayTemplates)}
          >
            {t('templates')}
            {!hideShowAndHideTemplatesIcon && getIcon()}
          </div>
          <WuButton variant={'secondary'} onClick={handleSeeAllTemplate}>
            {t('seeAllTemplates')}{' '}
          </WuButton>
        </div>
        {displayTemplates && (
          <div className={styles.gridContainer}>{templates}</div>
        )}
      </div>
      {showAllTemplateModal &&
        ReactDOM.createPortal(
          <AllTemplatesModal closePopup={closePopup} />,
          modalRoot,
        )}
    </div>
  );
};
