import { faPlus } from '@fortawesome/free-solid-svg-icons';
import styles from './LivePollListTopBar.module.css';
import { useTranslation } from 'react-i18next';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Props {
  onAddNewLivePoll: () => void;
}

export const LivePollListTopBar = ({ onAddNewLivePoll }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.topBarContainer} data-testid="topbar">
      <div className={styles.newLivePollContainer}>
        <WuButton
          variant={'primary'}
          onClick={onAddNewLivePoll}
          icon={<FontAwesomeIcon icon={faPlus} />}
          iconPosition="left"
        >
          {t('createNew')}
        </WuButton>
      </div>
    </div>
  );
};
