import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import { NavbarItemType } from './NavbarItemType';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

interface Props {
  text: string;
  active?: boolean;
  url?: string;
  onClick?: () => any;
  type?: NavbarItemType;
}

export const NavItem = (props: Props) => {
  let item = (
    <Link to={props.url!} className={props.active ? styles.activeClass : ''}>
      {props.text}
    </Link>
  );
  if (props.type === NavbarItemType.BUTTON) {
    item = <WuButton onClick={props.onClick!}> {props.text} </WuButton>;
  }

  return <li>{item}</li>;
};
