import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { LivePollSessionState } from '@livepolls/ui-components/src/enums/livepoll-session-state.enum';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { LivePollSessionType } from '@livepolls/ui-components/src/interfaces/livePoll-session-type.interface';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useAddLivePollSessionMutation,
  useLivePollSessions,
} from 'src/hooks/store-hooks';
import { ILivePollSessionSetting } from 'src/models/livepoll-session-setting.interface';
import { livePollTypeSelector } from 'src/stores/livePoll-store.selectors';
import { useLivePollEditorStore } from 'src/stores/useLivePollEditor.store';
import { getFourDigitPin } from 'src/utils/FourDigitPin';
import { useLivePollIdFromUrl } from 'src/utils/hooks.util';
import googleSlidesIcon from '../../images/google-slides.svg';
import zoomIcon from '../../images/zoom.svg';
import { goToTakeLivePoll } from '../navbar/app.urls';
import { StartLivePollModal } from './start-livepoll-modal';
import styles from './start-livepoll-split-button.module.css';
import { WuButton } from '@npm-questionpro/wick-ui-lib';

const GOOGLE_SLIDES_LINK =
  'https://chromewebstore.google.com/detail/livepolls-for-google-slid/cbieiahadepfabmenmldnffmjpmbajam';
const ZOOM_LINK = 'https://marketplace.zoom.us/apps/4IoGDt-0TfurGbOBIh7fLw';

function formatDate(timeString: string): string {
  const time = new Date(timeString);
  const hour = time.getHours();
  const minute = time.getMinutes();

  return (
    (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
  );
}

export const StartPollSplitButton = () => {
  const { t } = useTranslation();

  const addLivePollSessionMutation = useAddLivePollSessionMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigateFn = useNavigate();
  const ref = useRef(null);
  const mountedRef = useRef<Boolean>(false);
  const [displayDropDOwn, setDisplayDropDown] = useState<Boolean>(false);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const activeLivePollId = useLivePollIdFromUrl();

  const livePollType = useLivePollEditorStore(livePollTypeSelector);

  useEffect(() => {
    const livePollSession = addLivePollSessionMutation.data;
    if (livePollSession && mountedRef.current) {
      mountedRef.current = false;
      goToTakeLivePoll(navigateFn, activeLivePollId, livePollSession.id);
    }
  }, [activeLivePollId, addLivePollSessionMutation.data, navigateFn]);

  const {
    isLoading,
    isError,
    error,
    data: livePollSessions,
  } = useLivePollSessions(activeLivePollId);

  if (isError) {
    return <div>Oops an error occurred. ({error?.message})</div>;
  }

  if (isLoading) {
    return <LpSpinner message="" customSpinnerClass={styles.customSpinner} />;
  }

  const gotoLivePollSessionScreen = (livePollSessionId: number) => {
    goToTakeLivePoll(navigateFn, activeLivePollId, livePollSessionId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleAddLivePollSession = async (data: ILivePollSessionSetting) => {
    addLivePollSessionMutation.mutate({
      livePollId: activeLivePollId,
      data: { ...data },
    });
  };

  const activeLivePollSessions = livePollSessions!.filter(livePollSession =>
    [
      LivePollSessionState.QUESTION_STARTED,
      LivePollSessionState.QUESTION_FINISHED,
      LivePollSessionState.WAITING_ROOM,
    ].some(
      state =>
        livePollSession.state === state &&
        livePollSession.type === LivePollSessionType.PRESENTER_SESSION,
    ),
  );

  let dropdownContent: ReactNode = activeLivePollSessions.map((item, i) => (
    <li
      className={styles['dd-list-item']}
      key={i}
      onClick={() => gotoLivePollSessionScreen(item.id)}
    >
      <div className={styles.sessionNameAndPin}>
        <div className={styles.sessionName}>{item.name || 'Untitled'}</div>
        <div className={styles.pin}> - {getFourDigitPin(item.pin)}</div>
      </div>
      <div className={styles.sessionTime}>
        <div>{formatDate(item.createdAt)}</div>
      </div>
    </li>
  ));

  const startText =
    livePollType === LivePollType.QUIZ
      ? `${t('start')}  ${t('quiz')}`
      : `${t('start')}  ${t('poll')}`;

  const openDropDown = () => {
    setDisplayDropDown(!displayDropDOwn);
  };

  const getSplitButtonIcon = () => {
    return displayDropDOwn ? (
      <FontAwesomeIcon icon={faCaretUp} size="1x" data-testid="upArrow" />
    ) : (
      <FontAwesomeIcon icon={faCaretDown} size="1x" data-testid="downArrow" />
    );
  };
  return (
    <>
      {showModal && (
        <StartLivePollModal
          onDeploy={handleAddLivePollSession}
          onCloseModal={handleCloseModal}
          livePollType={livePollType!}
          isLoading={addLivePollSessionMutation.isLoading}
          error={addLivePollSessionMutation.error}
        />
      )}

      <div className={styles['dd-wrapper']} ref={ref}>
        <div>
          <div className={styles['dd-header']}>
            <WuButton onClick={handleShowModal} variant="primary">
              {startText}
            </WuButton>

            <WuButton
              onClick={openDropDown}
              variant="primary"
              icon={getSplitButtonIcon()}
              className="min-w-0 p-3"
            />
          </div>
          {displayDropDOwn && (
            <div className={styles.presentWithIntegration}>
              <div className={styles.presentWithIntegrationText}>
                {t('integrateWith')}
              </div>
              <div className={styles.integration}>
                <div
                  className={styles.integrationText}
                  onClick={() => window.open(GOOGLE_SLIDES_LINK, '_newtab')}
                >
                  <img
                    src={googleSlidesIcon}
                    alt="google slides"
                    className={styles.integrationIcon}
                  />
                  {t('googleSlides')}
                </div>
                <div
                  className={styles.integrationText}
                  onClick={() => window.open(ZOOM_LINK, '_newtab')}
                >
                  <img
                    src={zoomIcon}
                    alt="zoom"
                    className={styles.integrationIcon}
                  />
                  {t('zoom')}
                </div>
              </div>
              {activeLivePollSessions.length > 0 && (
                <div>
                  <div className={styles.activeSession}>
                    {activeLivePollSessions.length !== 1
                      ? `${t('activeSessions')}`
                      : `${t('activeSession')}`}
                  </div>
                  <ul onClick={e => e.stopPropagation()}>{dropdownContent}</ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
